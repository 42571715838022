import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-survey-form',
  templateUrl: './survey-form.component.html',
  styleUrls: ['./survey-form.component.scss']
})
export class SurveyFormComponent implements OnInit {
  @Input() question: string;
  @Input() description: string;
  @Input() choices: string [];
  @Input() bgUrl: string;

  @Output() submit: EventEmitter<string> = new EventEmitter();
  @Output() back: EventEmitter<any> = new EventEmitter();

  selectedChoice: string;

  constructor() { }

  ngOnInit(): void {
    //this.selectedChoice = this.choices[0];
  }

  continue() {
    this.submit.emit(this.selectedChoice);
  }

  goBack() {
    this.back.emit();
  }
}
