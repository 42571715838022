
<div class="container-fluid">
  <section #section5 class="pt-3 pb-6">
    
    <app-heading-section 
      [titleKey]="'landing.search.h2'"
      [descriptionKey]="'landing.search.p'"
      [iconPath]="'assets/img/icons/common/search.png'"
      [wheelsArround]="true">
    </app-heading-section>

    <div>
      <div>
        <div>
         <div class="p-4">
          <search-bar 
            placeholder="{{ 'navbar.search_community_placeholder' | translate }}"
            (search)="handleTemplateSearch($event)">
          </search-bar>
         </div>
        </div>
      </div>
      <div class="w-100 overflow-hidden m-auto text-center py-3">
        <img src="assets/img/infography/micro-apps.webp">
      </div>
    </div>

  </section>
</div>