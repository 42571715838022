import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent {
  @Input() placeholder: string = '';
  @Output() search: EventEmitter<string> = new EventEmitter<string>();

  searchTerm: string = '';

  onSearchTermChange() {
    // You can emit the search term on each keystroke if you want live search
    // this.search.emit(this.searchTerm);
  }

  onSearch() {
    this.search.emit(this.searchTerm);
  }
  onKeydown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.onSearch();
    }
  }
}
