import { Component, Input } from "@angular/core";

@Component({
  selector: "app-testimonial-section",
  templateUrl: "./testimonial-section.component.html",
  styleUrls: ["./testimonial-section.component.scss"]
})
export class TestimonialSectionComponent {
  @Input() descriptionKey : string;
  @Input() bgCardClass: string;
  @Input() primaryColorClass: string;
  @Input() secondaryColorClass: string;
  constructor() {}
}

