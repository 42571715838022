import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {PlatformService} from './plateform.service';
import {IndividualConfig} from "ngx-toastr/toastr/toastr-config";

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(
    private readonly toastrService: ToastrService,
    private platformService: PlatformService
  ) {
  }

  success(message?: string, title?: string, override?: Partial<IndividualConfig>) {
    if (this.platformService.isPlatformServer()) return;

    return this.toastrService.success(message, title, override);
  }

  info(message?: string, title?: string, override?: Partial<IndividualConfig>) {
    if (this.platformService.isPlatformServer()) return;

    return this.toastrService.info(message, title, override);
  }

  warning(message?: string, title?: string, override?: Partial<IndividualConfig>) {
    if (this.platformService.isPlatformServer()) return;

    return this.toastrService.warning(message, title, override);
  }

  error(message?: string, title?: string, override?: Partial<IndividualConfig>) {
    if (this.platformService.isPlatformServer()) return;

    return this.toastrService.error(message, title, override);
  }

  clear(toastId: number) {
    if (this.platformService.isPlatformServer()) return;

    this.toastrService.clear(toastId);
  }
}
