<div class="media media-comment space-t">
    <img
        alt="Image placeholder"
        class="avatar avatar-lg media-comment-avatar rounded-circle"
        src="{{ imagePath }}" />
    <div class="media-body">
        <div class="media-comment-text">
            <p class="h5 mt-0 mb-2">
                <strong>
                    {{ title }}<br>
                    <small>{{ subTitle }}</small>
                </strong>
            </p>
           <h3 class="text-sm lh-160">
                {{ message }}
           </h3>
        </div>
    </div>
</div>
