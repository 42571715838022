import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-micro-app',
  templateUrl: './micro-app.component.html',
  styleUrls: ['./micro-app.component.scss']
})
export class MicroAppComponent implements OnInit {

  @Input() isPlural: boolean=true;
  constructor() { }

  ngOnInit(): void {
  }
}