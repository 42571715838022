<div class="m-auto  text-justify text-md-center">
  <img *ngIf="wheelsArround" src="assets/img/icons/elements/wheel_primary.png" class="wheel-primary">
  <h2 class="display-1 mx-auto my-2" [innerHTML]="titleKey | translate"></h2> 
  <p *ngIf="descriptionKey" [innerHTML]="descriptionKey | translate" class="text-justify"></p>
  <ul *ngIf="descriptionListKey">
    <li *ngFor="let id of [1,2,3]" [innerHTML]="descriptionListKey + '.' + id | translate"></li>
  </ul>
  <p *ngIf="buttonKey">
    <a (click)="redirectOnClick()" class="btn {{buttonClass}} text-white">
      <span class="btn-inner--text"><strong>{{ buttonKey | translate }}</strong></span>
    </a>
  </p>
</div>