import { Component, OnInit, Input} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { signIn } from 'src/app/store/auth/auth.actions';
import { CredentialResponse, PromptMomentNotification } from 'google-one-tap';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { PlatformService } from 'src/app/services/plateform.service';
import { TrackingLoginRegister } from 'src/app/models/tracking.model';
import { TrackingService } from 'src/app/services/tracking.service';

// declare global {
//   const google: typeof import('google-one-tap');
// }

@Component({
  selector: 'google-button',
  templateUrl: './google-button.component.html',
  styleUrls: ['./google-button.component.scss']
})
export class GoogleButtonComponent implements OnInit {
  signGroup: UntypedFormGroup;
  
  constructor(
    private fb: UntypedFormBuilder, 
    private store: Store, 
    private router: Router, 
    private trackingService: TrackingService,
    private platformeService: PlatformService) { }

  ngOnInit(): void {
    if(this.platformeService.isPlatformBrowser()){
      this.loadGoogleScript();
      // BEGIN Google One Tap Sign ****

      // @ts-ignore
      window.onGoogleLibraryLoad = () => {
        // @ts-ignore
        google.accounts.id.initialize({
          client_id:environment.GOOGLE_CLIENT_ID,
          callback: this.submitGoogleProvider.bind(this), 
          auto_select: false, 
          cancel_on_tap_outside: true
        });
        // @ts-ignore
        google.accounts.id.renderButton(
          // @ts-ignore
          document.getElementById("googleButton"),{ 
            theme: "outline", 
            size: "large",
            width: 100});
        // @ts-ignore
        google.accounts.id.prompt((notification: PromptMomentNotification) => {});
      }
    }
  }
  // END Google One Tap Sign ****

  async submitGoogleProvider(response: CredentialResponse) {
    this.signGroup = this.fb.group({
      email: [''],
      password: [''],
      token: [''] 
    })
    this.signGroup.patchValue({
      token: response.credential
    })

    this.trackLogin();
    this.store.dispatch(signIn(this.signGroup.value));
  }

  public loadGoogleScript() {
    let body = <HTMLDivElement> document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }


  trackLogin() {
    let trackingDTO: TrackingLoginRegister = {
      eventType: 'login',
      pageUrl: this.router.url
    }
    this.trackingService.track(trackingDTO);
  }
}