import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { selectIsUserSubscribedToPirogPro } from 'src/app/store/auth/auth.selectors';
import { Store } from "@ngrx/store";
import { PirogProModalComponent } from '../pirog-pro-modal/pirog-pro-modal.component';

@Component({
  selector: 'pirog-pro-button',
  templateUrl: './pirog-pro-button.component.html',
  styleUrls: ['./pirog-pro-button.component.scss']
})
export class PirogProButtonComponent implements OnInit {
  @ViewChild('pirog_pro_modal') private pirogProModal: PirogProModalComponent

  @Input() enableText : boolean = true;
  @Input() buttonText : any = 'Pirog Pro';
  @Input() textColor : string = 'black';
  @Input() iconMode : boolean = true;
  @Input() modalMode : boolean = true;
  @Input() currentStepRecap:string;
  @Input() border: boolean = false; 
  @Input() gold: boolean = false;
  isSubscribedToPirogPro$: Observable<boolean>;
  
  constructor(private store: Store) { }

  ngOnInit(): void {
    this.isSubscribedToPirogPro$ = this.store.select(selectIsUserSubscribedToPirogPro);
  }

  openPirogProModal() {
    if(this.modalMode) {
      this.pirogProModal.open();
    }
  }
}