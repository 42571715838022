import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ControlErrorModule} from './control-error/control-error.module';
import {ControlErrorService} from './control-error/control-error.service';
import {LoaderComponent} from './loader/loader.component';
import {LoaderMinComponent} from './loader-min/loader-min.component';
import {TemplateYoutubeComponent} from './template-youtube/template-youtube.component';
import {PageHeaderComponent} from './page-header/page-header.component';
import {HelpButtonComponent} from './help-button/help-button.component';
import {HaveToVerifyAccountComponent} from './have-to-verify-account/have-to-verify-account.component';
import {IconErrorAnimatedComponent} from './icon-error-animated/icon-error-animated.component';
import {IconSuccessAnimatedComponent} from './icon-success-animated/icon-success-animated.component';
import {TranslateModule} from '@ngx-translate/core';
import {AngularEditorModule} from '@kolkov/angular-editor';
import {PirogProModalComponent} from './pirog-pro-modal/pirog-pro-modal.component';
import {ModalModule} from 'ngx-bootstrap/modal';
import {CareerChoiceCardComponent} from './pirog-pro-modal/career-choice-card/career-choice-card.component';
import {SurveyFormComponent} from './pirog-pro-modal/survey-form/survey-form.component';
import {RecapCardComponent} from './pirog-pro-modal/recap-card/recap-card.component';
import {FormsModule} from '@angular/forms';
import {PirogProButtonComponent} from './pirog-pro-button/pirog-pro-button.component';
import {GoogleButtonComponent} from './google-button/google-button.component';
import {TestimonialMessageComponent} from './testimonial-message/testimonial-message.component';
import {LiCustomComponent} from './li-custom/li-custom.component';
import {ProfileImageComponent} from './profile-image/profile-image.component';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {TagInputModule} from 'ngx-chips';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {SearchBarComponent} from './search-bar/search-bar.component';
import {ProductsBannerComponent} from './products-banner-home/products-banner.component';
import {HeadingSectionComponent} from './heading-section/heading-section.component';
import {ParagraphSectionComponent} from './paragraph-section/paragraph-section.component';
import {ThreeStepsComponent} from './three-steps/three-steps.component';
import {SearchSectionComponent} from './search-section/search-section.component';
import {TestimonialSectionComponent} from './testimonial-section/testimonial-section.component';
import {ProductsListComponent} from './products-list/products-list.component';
import {ResourcesListComponent} from './resources-list/resources-list.component';
import {SubNavItemComponent} from './sub-nav-item/sub-nav-item.component';
import {MicroAppComponent} from "./micro-app/micro-app.component";
import {AppLoadingComponent} from "./app-loading/app-loading.component";
import {FooterComponent} from "./footer/footer.component";
import {RouterModule} from "@angular/router";


@NgModule({
  declarations: [
    LoaderComponent,
    LoaderMinComponent,
    TemplateYoutubeComponent,
    HelpButtonComponent,
    HaveToVerifyAccountComponent,
    IconErrorAnimatedComponent,
    IconSuccessAnimatedComponent,
    MicroAppComponent,
    PirogProModalComponent,
    CareerChoiceCardComponent,
    SurveyFormComponent,
    RecapCardComponent,
    PirogProButtonComponent,
    GoogleButtonComponent,
    TestimonialMessageComponent,
    LiCustomComponent,
    ProfileImageComponent,
    PageHeaderComponent,
    SearchBarComponent,
    ProductsBannerComponent,
    HeadingSectionComponent,
    ParagraphSectionComponent,
    ThreeStepsComponent,
    SearchSectionComponent,
    TestimonialSectionComponent,
    ProductsListComponent,
    ResourcesListComponent,
    SubNavItemComponent,
    AppLoadingComponent,
    FooterComponent
  ],
  imports: [
    CommonModule,
    ControlErrorModule,
    TranslateModule,
    AngularEditorModule,
    FormsModule,
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    TagInputModule,
    BsDropdownModule.forRoot(),
    RouterModule
  ],
  exports: [
    ControlErrorModule,
    TagInputModule,
    LoaderComponent,
    LoaderMinComponent,
    BsDropdownModule,
    TemplateYoutubeComponent,
    HelpButtonComponent,
    HaveToVerifyAccountComponent,
    IconErrorAnimatedComponent,
    IconSuccessAnimatedComponent,
    TranslateModule,
    MicroAppComponent,
    AngularEditorModule,
    PirogProModalComponent,
    PirogProButtonComponent,
    GoogleButtonComponent,
    TestimonialMessageComponent,
    LiCustomComponent,
    ProfileImageComponent,
    PaginationModule,
    PageHeaderComponent,
    SearchBarComponent,
    ProductsBannerComponent,
    HeadingSectionComponent,
    ParagraphSectionComponent,
    ThreeStepsComponent,
    SearchSectionComponent,
    TestimonialSectionComponent,
    ProductsListComponent,
    ResourcesListComponent,
    SubNavItemComponent,
    AppLoadingComponent,
    FooterComponent
  ],
  providers: [ControlErrorService],
})
export class SharedModule {
}
