import { Component, OnInit } from "@angular/core";
import { Router } from '@angular/router';
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { Template } from "src/app/models/template.model";
import { selectTemplates } from 'src/app/store/repository/repository.selector';

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"]
})
export class FooterComponent implements OnInit {
  test: Date = new Date();
  templates$: Observable<Template[]>;

  constructor(private store: Store, private router: Router) {}

  ngOnInit() {
    this.templates$ = this.store.select(selectTemplates);
  }
}
