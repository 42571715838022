import { Component, Input } from "@angular/core";
import { Router } from '@angular/router';
import { Template } from "src/app/models/template.model";
import { TrackingClickAction } from "src/app/models/tracking.model";
import { TrackingService } from "src/app/services/tracking.service";

@Component({
  selector: "app-paragraph-section",
  templateUrl: "./paragraph-section.component.html",
  styleUrls: ["./paragraph-section.component.scss"]
})
export class ParagraphSectionComponent {
  @Input() titleKey : string;
  @Input() descriptionKey : string;
  @Input() descriptionListKey : string;
  @Input() buttonKey = 'bg-primary';
  @Input() buttonClass: string;
  @Input() route: string;
  @Input() templateId: string;
  @Input() templateName: string;
  @Input() wheelsArround : boolean = false;
  
  constructor(private router: Router, private trackingService: TrackingService) {}

  redirectOnClick(): void {
    if(this.templateName){
      this.router.navigate([this.route], { queryParams: { name: this.templateName } });
      this.trackTemplateClick();
    } else {
      this.router.navigate([this.route]);
    } 
  }

  trackTemplateClick() {
    let trackingDTO: TrackingClickAction = {
      eventType: 'clickAction',
      actionDescription: 'create a ' + this.templateName + '| Store Page',
      pageUrl: this.router.url,
      templateId: this.templateId,
      templateName: this.templateName,
      btnName: 'store_' + this.templateId
    }
    this.trackingService.track(trackingDTO);
  }
}
