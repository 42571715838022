import { Component, Input } from "@angular/core";
import { Router } from '@angular/router';

@Component({
  selector: "page-header",
  templateUrl: "./page-header.component.html",
  styleUrls: ["./page-header.component.scss"]
})
export class PageHeaderComponent {
  @Input() pageName : string;
  @Input() mainTitle : string;
  @Input() mainTitleDescription : string;
  @Input() mainTitleClass : string;
  @Input() description : string;
  @Input() iconPath : string;
  @Input() bgColorClass : string;
  
  constructor(private router: Router) {}

  redirectToHome() {
    this.router.navigate(['/']);
  }
}
