import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../models/user.model';
import { UpdateAccountRequest } from '../models/update-account-request.model';
import { ResetPasswordAttemptRequest } from '../models/reset-password-attempt-request.model';
import { ResetPasswordProcessRequest } from '../models/reset-password-process-request.model';
import { AddAccountDetailsRequest } from '../models/add-account-details-request.model';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    constructor(private httpClient: HttpClient) {}

    // CRUD User

    public getUserById(idUser: string): Observable<User> {
        return this.httpClient.get<User>(`accounts/${idUser}`);
    }

    public updateUser(account: UpdateAccountRequest): Observable<User> {
        return this.httpClient.put<User>(`accounts/${account.id}`, account);
    }
    
    public setUserDetails(request: AddAccountDetailsRequest) {
        return this.httpClient.post(`account_details`, request);
    }

    public deleteAccount(idUser: string) {
        return this.httpClient.delete(`accounts/${idUser}`);
    }

    // Email verification
    public verifyEmailWithMailByAccountId(id: String): Observable<any> {
        return this.httpClient.get(`accounts/${id}/verify-email/attempt`);
    }

    public verifyEmailWithMailClic(token: String): Observable<any> {
        return this.httpClient.post(`accounts/verify-email/process`, { token: token });
    }

    // Reset password
    public resetPasswordAttempt(resetPasswordAttemptRequest: ResetPasswordAttemptRequest): Observable<any> {
        return this.httpClient.post(`accounts/reset-password/attempt`, resetPasswordAttemptRequest);
    }

    public resetPasswordProcess(resetPasswordProcessRequest: ResetPasswordProcessRequest): Observable<any> {
        return this.httpClient.post(`accounts/reset-password/process`, resetPasswordProcessRequest);
    }

    // Pirog PRO
    public unsubscribeFromPirogPro() {
        return this.httpClient.post(`accounts/unsubscribe`, {});
    }
}
