import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../models/user.model';
import { UpdateAccountRequest } from '../models/update-account-request.model';
import { Template } from '../models/template.model';
import { TemplateRequest } from '../models/template-request.model';
import { ApplicationCreationRequest } from '../models/application-creation-request.model';
import { Application } from '../models/application.model';
import { DomainType } from '../models/domain-type.model';
import { ApplicationRequest, ApplicationRequests } from '../models/application-request.model';
import { ApplicationRequestEntry } from '../models/application-request-entry.model';
import { ApplicationCreationResponse } from '../models/application-creation-response.model';
import { UpdateApplicationRequest } from '../models/update-application-request.model';
import { UpdateApplicationRequestsOrder} from '../models/update-application-requests-order.model';
import { updateApplication } from '../models/update-application-model';
import { PagedResult } from '../models/paged-result.model';
import { FilteredApplicationsRequest } from '../models/filtred-applications.model';
import { PublicationRequest } from '../models/publication-request.model';
import { ApplicationPublic } from '../models/application-public.model';
import { ApplicationReviewRequest } from '../models/app-review-request.model';
import { UpdatePublicInfo } from '../models/update-public-info.model';

@Injectable({
    providedIn: 'root',
})
export class ApplicationService {
    constructor(private httpClient: HttpClient) { }

    // CRUD Applications

    public getApplications() {
        return this.httpClient.get<Application[]>(`applications`);
    }

    public getApplicationById(id: string) {
        return this.httpClient.get<Application>(`applications/${id}`);
    }

    public deleteApplicationById(id: string) {
        return this.httpClient.delete(`applications/${id}`);
    }

    // CRUD Applications

    public getFilteredApplications(request: FilteredApplicationsRequest) {
        return this.httpClient.post<PagedResult<ApplicationPublic>>('applications/filter/public', request);
    }

    public getApplicationRequestsByApplicationId(applicationId: string) {
        return this.httpClient.get<ApplicationRequests>(`applications/${applicationId}/apprequests`);
    }

    public checkDomainAvailability(domainName: string) {
        return this.httpClient.get(`applications/domain/${domainName}/available`);
    }

    public createApplication(request: ApplicationCreationRequest) {
        return this.httpClient.post<ApplicationCreationResponse>(`applications`, request);
    }

    public addApplicationRequest(request: ApplicationRequestEntry) {
        return this.httpClient.post(`applications/apprequest`, request);
    }

    public updateApplicationRequest(request: UpdateApplicationRequest) {
        return this.httpClient.put(`applications/apprequest`, request);
    }

    public updateApplicationRequestsOrder(request: UpdateApplicationRequestsOrder) {
        return this.httpClient.put(`applications/apprequest/order`, request);
    }

    public updateApplication(request: updateApplication, id: string) {
        return this.httpClient.put(`applications/${id}`, request);
    }

    public completePaymentByApplicationId(id: string) {
        return this.httpClient.post<ApplicationCreationResponse>(`applications/${id}/complete`, {});
    }

    public deleteApplicationRequest(id: string) {
        return this.httpClient.delete(`applications/apprequest/${id}`);
    }

    public requestPublication(request: PublicationRequest) {
        return this.httpClient.post(`applications/publish`, request);
    }

    public updatePublicInfo(request: UpdatePublicInfo) {
        return this.httpClient.put(`applications/publish/update`, request);
    }

    public getAppsWithPublicationRequest() {
        return this.httpClient.get<ApplicationPublic[]>(`applications/publish/requests`);
    }
    
    public unpublishApplicationById(id: string) {
        return this.httpClient.post(`applications/${id}/unpublish`, {});
    }

    public reviewApplicationPublicationRequest(request: ApplicationReviewRequest) {
        return this.httpClient.post(`applications/publish/review`, request);
    }
}
