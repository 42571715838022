import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-resources-list",
  templateUrl: "./resources-list.component.html",
  styleUrls: ["./resources-list.component.scss"]
})
export class ResourcesListComponent implements OnInit {
  @Output() closeMenu = new EventEmitter<void>();

  resources: Array<{ name: string; path: string; imgPath: string; altText: string }> = [
    {
      name: "Explore",
      path: "/explore",
      imgPath: "assets/img/icons/common/search.png",
      altText: "pirog-explore"
    },
    {
      name: "Documentation",
      path: "/doc",
      imgPath: "assets/img/icons/common/documentation.png",
      altText: "pirog-documentation"
    }
  ];

  constructor(private router: Router) {}

  ngOnInit(): void {}

  redirectToResourcePage(path: string) {
    this.router.navigate([path]);
    this.closeMenu.emit();

  }
}
