import { createReducer, on } from '@ngrx/store';
import { addLoading, removeLoading } from './loader.action';

export const loaderFeatureKey = 'loader';

export interface LoaderState {
    counter: number;
}

export const initialState: LoaderState = {
    counter: 0,
};

export const reducer = createReducer(
    initialState,
    on(addLoading, (state) => {
        return {
            ...state,
            counter: state.counter + 1,
        };
    }),
    on(removeLoading, (state) => {
        return {
            ...state,
            counter: state.counter - 1,
        };
    })
);
