import { createAction, props } from '@ngrx/store';
import { ResetPasswordAttemptRequest } from 'src/app/models/reset-password-attempt-request.model';
import { ResetPasswordProcessRequest } from 'src/app/models/reset-password-process-request.model';
import { UpdateAccountRequest } from 'src/app/models/update-account-request.model';
import { User } from '../../models/user.model';

export const loadAuth = createAction('[Auth] Load Auth');

export const loadAuthSuccess = createAction(
    '[Auth] Load Auth Success',
    props<{ token: string; userId: string; role: string }>()
);

export const loadAuthFailure = createAction('[Auth] Load Auth Failure', props<{ error: string }>());

export const signIn = createAction('[Auth] Connection', props<{ email: string; password: string; token:string }>());

export const signInSuccess = createAction(
    '[Auth] Connection Success',
    props<{ bearer: string; role: string; userId: string }>()
);

export const signInFailure = createAction('[Auth] Connexion Failure', props<{ error: any }>());

export const logout = createAction('[Auth] Logout');

export const signUp = createAction('[Auth] Register', props<{ user: User }>());

export const signUpSuccess = createAction('[Auth] Register Success');

export const signUpFailure = createAction('[Auth] Register Failure', props<{ error: any }>());

export const loadUserById = createAction('[Auth] Load User by id ');

export const loadUserByIdSuccess = createAction('[Auth] Load User by id  Success', props<{ user: User }>());

export const loadUserByIdFailure = createAction('[Auth] Load User by id  Failure', props<{ error: string }>());

export const updateUser = createAction('[Auth] Update User ', props<{ user: UpdateAccountRequest }>());
export const updateUserSuccess = createAction('[Auth] Update User Success ', props<{ user: User }>());

export const updateUserFailure = createAction('[Auth] Update User Failure ', props<{ error: any }>());


export const verifyAccount = createAction('[Auth] Verify account ', props<{ token: string }>());

export const verifyAccountSuccess = createAction('[Auth] Verify account  Success');

export const verifyAccountFailure = createAction('[Auth] Verify account  Failure', props<{ error: any }>());

export const verifyAccountAttempt = createAction('[Auth] Verify account attempt', props<{ idUser: string }>());

export const verifyAccountAttemptSuccess = createAction('[Auth] Verify account attempt  Success');

export const verifyAccountAttemptFailure = createAction(
    '[Auth] Verify account attempt Failure',
    props<{ error: any }>()
);

export const resetPasswordAttempt = createAction(
    '[Auth] Reset Password attempt ',
    props<{ resetPasswordAttemptRequest: ResetPasswordAttemptRequest }>()
);

export const resetPasswordAttemptSuccess = createAction('[Auth] Reset Password attempt  Success');

export const resetPasswordAttemptFailure = createAction(
    '[Auth] Reset Password attempt Failure',
    props<{ error: any }>()
);

export const resetPasswordProcess = createAction(
    '[Auth] Reset Password Process ',
    props<{ resetPasswordProcessRequest: ResetPasswordProcessRequest }>()
);

export const resetPasswordProcessSuccess = createAction('[Auth] Reset Password process  Success');

export const resetPasswordProcessFailure = createAction(
    '[Auth] Reset Password process Failure',
    props<{ error: any }>()
);