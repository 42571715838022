import { createAction, props } from '@ngrx/store';
import { Application } from 'src/app/models/application.model';

export const loadSelectedApplication = createAction('[Repository] Load Selected Application', props<{ idApplication: string }>());

export const loadSelectedApplicationSuccess = createAction('[Repository] Load Selected Application Success', props<{ application: Application }>());

export const loadSelectedApplicationFailure = createAction('[Repository] Load Selected Application Failure');

export const loadSelectedApplicationIsConfigComplete = createAction('[Repository] loadSelectedApplicationIsConfigComplete', props<{ idApplication: string }>());

export const loadSelectedApplicationIsConfigCompleteSuccess = createAction('[Repository] loadSelectedApplicationIsConfigCompleteSuccess', props<{ isConfigComplete: boolean }>());

export const loadSelectedApplicationIsConfigCompleteFailure = createAction('[Repository] loadSelectedApplicationIsConfigCompleteFailure');