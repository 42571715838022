<div class="pt-5 pb-3">
  <img *ngIf="wheelsArround" src="assets/img/icons/elements/3_wheels.png" class="wheel-1-custom seo_ratio_1">
  <img *ngIf="wheelsArround" src="assets/img/icons/elements/2_wheels.png" class="wheel-2-custom seo_ratio_1">
  <div class="row flex-column align-items-center">
    <div *ngIf="iconPath">
      <img [src]="iconPath" class="seo_ratio_1 m-3">
    </div>
    <div class="text-center">
      <h2 class="display-1 mx-auto my-2" [innerHTML]="titleKey | translate"></h2>  
      <p class="lead ml-5 mr-5 text-justify text-md-center" [innerHTML]="descriptionKey | translate">{{ 'landing.search.p' | translate}}</p>
    </div>
  </div>
</div>
