<div class="d-flex align-items-center">
  <span *ngIf="(user$ | async)?.picture; else initials" class="d-flex avatar rounded-circle" [ngClass]="{'avatar-sm': avatarSize === 'sm', 'avatar-xl': avatarSize === 'xl'}">
    <img alt="User avatar" [src]="(user$ | async)?.picture" />
  </span>

  <ng-template #initials>
    <span class="bg-secondary avatar rounded-circle" [ngClass]="{'avatar-sm': avatarSize === 'sm', 'avatar-xl': avatarSize === 'xl'}">
      {{ firstName }}{{ lastName }}
    </span>
  </ng-template>

  <span *ngIf="showName" class="mb-0 ml-2 text-sm font-weight-bold">
    {{ (user$ | async)?.firstname }} {{ (user$ | async)?.lastname }}
  </span>
</div>
