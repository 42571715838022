<div class="row mr-0 ml-0">
    <div class="col-md-6 spaced">
        <div class="d-flex align-items-start flex-column h-100">
            <div class="mb-auto p-2">
                <h2 class="h2 mt-2 mb-4">
                    <span class="d-block mb-1">
                        <a class="h1 mr-3" (click)="goBack()">
                            <i class="ni ni-bold-left small"></i>
                        </a>
                        {{question}}
                    </span>
                    <small class="h3 font-weight-light text-muted">
                        {{ description }}
                    </small>
                </h2>
                <div class="custom-control custom-radio mt-2" *ngFor="let choice of choices">
                    <input type="radio" class="custom-control-input" id="{{choice}}" name="{{choice}}" [value]="choice" [(ngModel)]="selectedChoice" />

                    <label class="custom-control-label" for="{{choice}}">
                       {{'pirog_pro.choice.' + (choice | lowercase) | translate}}
                    </label>
                </div>
            </div>
            <div class="p-2 w-100"><button class="btn btn-primary text-center w-100" (click)="continue()">Continuer</button></div>
        </div>
    </div>
    <div
        class="col-md-6"
        style="background-image: url('assets/img/pro/{{bgUrl}}.webp');background-repeat: no-repeat;background-size: cover;">
        <span class="position-absolute close-button pointer" (click)="goBack()">
            <i class="ni ni-fat-remove lead text-black"></i>
          </span>
    </div>
</div>
