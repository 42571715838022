import { Injectable, Inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  constructor(
    private titleService: Title,
    private meta: Meta,
    private translateService: TranslateService
  ) {}

  setSeoTags(tags: { titleKey: string; descriptionKey?: string; imageKey?: string; noindex?: boolean }) {
    
    // Fetch translations asynchronously using forkJoin
    forkJoin([
      this.translateService.get(tags.titleKey),
      tags.descriptionKey ? this.translateService.get(tags.descriptionKey) : null,
      this.translateService.get('seo.author')
    ]).subscribe(([translatedTitle, translatedDescription, author]) => {
      
      // Title
      this.titleService.setTitle(translatedTitle);
      this.meta.updateTag({ name: 'author', content: author });
      
      // Description
      if (translatedDescription) {
        this.meta.updateTag({ name: 'description', content: translatedDescription });
        this.meta.updateTag({ property: 'og:description', content: translatedDescription });
      }

      // Open Graph
      this.meta.updateTag({ property: 'og:url', content: environment.seoUrl });
      this.meta.updateTag({ property: 'og:title', content: translatedTitle });
      this.meta.updateTag({
        property: 'og:image',
        content: tags.imageKey ? this.translateService.instant(tags.imageKey) : `${environment.seoUrl}/assets/img/opengraph/home.webp`
      });

      // NoIndex
      if (tags.noindex) {
        this.meta.updateTag({ name: 'robots', content: 'noindex' });
      }

      // NoIndex all pages in DEV environment
      if (environment.noindex) {
        this.meta.updateTag({ name: 'robots', content: 'noindex' });
      }
    });
  }

  // New method to retrieve current SEO tags
  getSeoTags() {
    const tags = {
      title: this.titleService.getTitle(),
      description: this.getMetaTagContent('description'),
      author: this.getMetaTagContent('author'),
      ogTitle: this.getMetaTagContent('og:title'),
      ogDescription: this.getMetaTagContent('og:description'),
      ogImage: this.getMetaTagContent('og:image'),
      robots: this.getMetaTagContent('robots'),
    };
    return tags;
  }

  private getMetaTagContent(name: string): string | null {
    const tag = this.meta.getTag(`name='${name}'`) || this.meta.getTag(`property='${name}'`);
    return tag ? tag.content : null;
  }
}
