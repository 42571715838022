<div class="header bg-light overflow-hidden">
  <div class="d-flex align-items-lg-center py-5 shadow-1-strong {{bgColorClass}}">
    <div class="container">
      <div class="row">
        <div class="col">
          <p><small>
            <a (click)="redirectToHome()">{{ 'landing_name' | translate }} > </a> {{ pageName | translate }}
          </small></p>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-8 z_index_top">
          <div class="text-left">
            <h1 class="display-1">
              <b class="{{mainTitleClass}}" [innerHTML]="mainTitle | translate"></b>
              <span class="text-black" [innerHTML]="mainTitleDescription | translate"></span>
            </h1>
            <p [innerHTML]="description | translate"></p>
          </div>
        </div>
        <div class="col-lg-4 z_index_back d-none d-lg-block">
          <img src="assets/img/icons/elements/roue_light.png" class="bg_custom seo_ratio_1"/>
          <img [src]="iconPath" class="icone_custom seo_ratio_1"/>
        </div>
      </div>
    </div>
  </div>
</div>

