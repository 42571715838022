<div class="row">
  <div *ngFor="let resource of resources" class="col-6 col-lg-3">
    <app-sub-nav-item 
      [imageUrl]="resource.imgPath" 
      [title]="resource.name" 
      [description]="" 
      [path]="resource.path"
      (click)="redirectToResourcePage(resource.path)">

    </app-sub-nav-item>
  </div>
</div>
