import { Component } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-search-section",
  templateUrl: "./search-section.component.html",
  styleUrls: ["./search-section.component.scss"]
})
export class SearchSectionComponent {
  
  constructor(private router: Router) {}

  handleTemplateSearch(searchTerm: string) {
    this.router.navigate(['/explore'], { queryParams: { query: searchTerm } });
  }  
}