import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Application } from 'src/app/models/application.model';
import { ApplicationService } from 'src/app/services/application.service';
import { environment } from 'src/environments/environment';
import * as Stomp from 'stompjs';
//import SockJS from 'sockjs-client';


@Component({
  selector: 'app-app-loading',
  templateUrl: './app-loading.component.html',
  styleUrls: ['./app-loading.component.scss']
})
export class AppLoadingComponent implements OnInit {
  idApplication: string;
  stompClient: any = null;
  baseUrl = environment.baseUrl;
  application: Application;
  messageTranslateKey;

  constructor(private route: ActivatedRoute, private router: Router, private applicationService: ApplicationService) {}

  ngOnInit(): void {
    this.getParamsFromRoute();
  }

  private getParamsFromRoute(): void {
    this.route.params.subscribe((params) => {
      this.idApplication = params['applicationId'];
      if (this.idApplication) {
        this.initData();
      }
    });
  }

  private initData(): void {
    this.applicationService
      .getApplicationById(this.idApplication)
      .subscribe((response) => {
        this.application = response;
        this.handleState(this.application.state)
        this._subscribeToAppStatus();
      });
  }

  async _subscribeToAppStatus() {
    //const socket = new SockJS(`${this.baseUrl}/api/ws`);
    //this.stompClient = Stomp.over(socket);
    const _this = this;

    await this.stompClient.connect({}, function (frame: string) {
        // TODO: Etudier une nouvelle façon de subscribe avec le store
        _this.stompClient.subscribe(
            `/start/application/status/${_this.idApplication}`,
            function (response) {
                _this.handleState(response.body.replaceAll('"',''));
            }
        );
    });
  }

  redirectToDashboard() {
    this.router.navigate(['/dashboard','applications',this.application.id,'overview']);
  }

  private handleState(state: string) {
    switch(state) {
      case "INITIATED":
        this.messageTranslateKey = "app_loading.setting_up";
        break;
      case "DEPLOYING":
        this.messageTranslateKey = "app_loading.deploying";
        break;
      case "UP":
      case "DOWN":
        this.redirectToDashboard();
        break;
    }
  }

  async _disconnectSocket() {
      if (this.stompClient !== null) {
          await this.stompClient.disconnect();
      }
      console.log('Socket disconnected');
  }

  ngOnDestroy(): void {
      this._disconnectSocket();
  }
}
