import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {selectIsLoading} from "../../../store/loader/loader.selector";

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
    isLoading$: Observable<boolean>;
    constructor(private store: Store) {}

    ngOnInit(): void {
        this.isLoading$ = this.store.select(selectIsLoading);
    }
}
