import { Directive, ElementRef, HostListener } from '@angular/core';
import { ControlErrorService } from '../control-error.service';
import { PlatformService } from 'src/app/services/plateform.service';

/* tslint:disable:directive-selector */
@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'form',
})
export class ControlErrorFormSubmitDirective {
    constructor(private host: ElementRef<HTMLFormElement>, private platformService: PlatformService, private service: ControlErrorService) {}

    @HostListener('ngSubmit') onSubmit(): void {
        if (this.platformService.isPlatformBrowser()){
            
            if (this.element.classList.contains('submitted') === false) {
                this.element.classList.add('submitted');
            }
            this.service.triggerNext();
        }
    }

    get element(): HTMLFormElement {
        return this.host.nativeElement;
    }
}
