import { Component, Input } from "@angular/core";

@Component({
  selector: "app-three-steps",
  templateUrl: "./three-steps.component.html",
  styleUrls: ["./three-steps.component.scss"]
})
export class ThreeStepsComponent {
  @Input() descriptionKey : string;
  @Input() stepsImgPath : string;
  @Input() stepsIconesDir : string;
  @Input() buttonKey: string;
  constructor() {}
}