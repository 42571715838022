import { createSelector } from '@ngrx/store';
import { authFeatureKey, AuthState } from './auth.reducer';
import { AppState } from '../index';

export const authFeature = (state: AppState) => state[authFeatureKey];

export const selectToken = createSelector(authFeature, (state: AuthState) => state?.token);

export const selectErrorAuth = createSelector(authFeature, (state: AuthState) => state?.error);

export const selectErrorMessage = createSelector(authFeature, (state: AuthState) => state?.error?.message);

export const selectIsAuthAdmin = createSelector(authFeature, (state: AuthState): boolean => {
    return state?.user?.role === 'ROLE_ADMIN' && state?.token !== null;
});

export const selectIsAuth = createSelector(authFeature, (state: AuthState): boolean => {
    return !!state?.token;
});

export const selectUser = createSelector(authFeature, (state: AuthState) => state?.user);

export const selectUserId = createSelector(authFeature, (state: AuthState) => state?.userId);

export const selectVerifyAccountSuccess = createSelector(
    authFeature,
    (state: AuthState) => state?.verifyAccountSuccess
);

export const selectIsUserSubscribedToPirogPro = createSelector(authFeature, (state: AuthState) => state?.user?.subscribedToPirogPro);


export const selectHasAcceptedOurConditionsAndPrivacyPolicy = createSelector(authFeature, (state: AuthState): boolean => {
    return !!state?.user?.hasAcceptedOurConditionsAndPrivacyPolicy;
});
