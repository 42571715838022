<div class="row w-100 mr-0 ml-0">
    <div class="col-md-6 spaced">
        <div *ngIf="currentStepRecap === 'description'" class="d-flex align-items-start flex-column h-100 justify-content-between">
            <div class="p-2">
                <h1 class="h1">
                    {{'pirog_pro.recap.try_pirog' | translate}}
                </h1>

                <small class="h4 font-weight-light text-muted">
                    {{'pirog_pro.recap.description' | translate}}
                </small>
            </div>
            <div class="pb-6">
                <h3 class="my-4">{{'pirog_pro.recap.you_get_this' | translate}}</h3>
                <ul>
                    <li class="h4 font-weight-light">
                        &nbsp; {{'pirog_pro.advantages.unlimited' | translate}}
                    </li>
                    <li class="h4 font-weight-light">
                        &nbsp; {{'pirog_pro.advantages.pianalytics' | translate}}
                    </li>
                    <li class="h4 font-weight-light">
                        &nbsp; {{'pirog_pro.advantages.generate_bg' | translate}}
                    </li>
                </ul>
            </div>
            <div class="w-100"><button class="btn btn-primary text-center w-100" (click)="changeStep('terms')"> {{'pirog_pro.recap.start_free' | translate}}</button></div>
        </div>


        <div *ngIf="currentStepRecap === 'terms'" class="d-flex align-items-start flex-column h-100">
            <div class="mb-auto p-2">
                <h1 class="h1 mb-4"><a class="h1 mr-3" (click)="changeStep('description')"><i class="ni ni-bold-left small"></i></a>{{'pirog_pro.recap.try_pirog' | translate}}</h1>
                <div class="custom-control custom-radio mt-5">
                    <input type="radio" class="custom-control-input" id="subscription" name="subscription" [checked]="true" />

                    <label class="custom-control-label font-weight-bold" for="subscription">
                        {{'pirog_pro.recap.monthly' | translate}}
                    </label>
                    <p >{{'pirog_pro.recap.month' | translate}} </p>
                </div>
                <ul>
                    <li class="h4 font-weight-light">
                        &nbsp; {{'pirog_pro.recap.free_tier' | translate}}
                    </li>
                    <li class="h4 font-weight-light">
                        &nbsp; {{'pirog_pro.recap.cancel_all_times' | translate}}
                    </li>
                </ul>
            </div>
            <div class="mt-3 w-100"><button class="btn btn-primary text-center w-100" (click)="setupPaymentMethodForm()">{{'pirog_pro.recap.next' | translate}} </button></div>
            <small>{{'pirog_pro.recap.policy' | translate}}</small>
        </div>
        <div *ngIf="currentStepRecap === 'stripe_form'">
            <h1 class="h1 mb-4">
                <a class="h1 mr-3" (click)="cancelOnCardFormStep()">
                    <i class="ni ni-bold-left small"></i>
                </a>
                {{'pirog_pro.recap.try_pirog' | translate}}
            </h1>
        </div>
        <div #cardElement>
            <!-- The stripe card is inserted here-->
          </div>
    </div>

    <span class="position-absolute close-button pointer" (click)="closeModal()">
        <i class="ni ni-fat-remove"></i>
    </span>

    <div class="col-md-6 d-none d-md-block spaced m-auto border-left" >
        <div class="d-flex align-items-start flex-column h-100">
            <h2 class="text-center align-items-center">Pirog Pro</h2>
            <div class="p-2">
                <div class="timeline timeline-one-side" data-timeline-axis-style="dashed" data-timeline-content="axis">
                    <div class="timeline-block">
                        <span class="timeline-step badge-success">
                            <i class="ni ni-bell-55"> </i>
                        </span>

                        <div class="timeline-content">
                            <div class="d-flex justify-content-between pt-1">
                                <div>
                                    <span class="text-muted text-sm font-weight-bold">
                                        {{'pirog_pro.recap.timeline_first_title' | translate}}
                                    </span>
                                </div>
                            </div>

                            <h6 class="text-sm mt-1 mb-0">
                                {{'pirog_pro.recap.timeline_first_description' | translate}}
                            </h6>
                        </div>
                    </div>

                    <div class="timeline-block">
                        <span class="timeline-step badge-info">
                            <i class="ni ni-money-coins"> </i>
                        </span>

                        <div class="timeline-content">
                            <div class="d-flex justify-content-between pt-1">
                                <div>
                                    <span class="text-muted text-sm font-weight-bold">
                                        {{'pirog_pro.recap.timeline_second_title' | translate}}
                                    </span>
                                </div>
                            </div>

                            <h6 class="text-sm mt-1 mb-0">
                                {{'pirog_pro.recap.timeline_second_description' | translate}}
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
            <app-testimonial-message
                [title]="'Morgane BICHON'"
                [subTitle]="'morganebichon.fr'"
                [imagePath]="'assets/img/pro/morgane.webp'"
                [message]="'pirog_pro.recap.morgane_testemony' | translate">
            </app-testimonial-message>
        </div>
    </div>
</div>
