import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-error-animated',
  templateUrl: './icon-error-animated.component.html',
  styleUrls: ['./icon-error-animated.component.scss']
})
export class IconErrorAnimatedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
