import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { PlatformService } from 'src/app/services/plateform.service';

@Component({
  selector: 'app-template-youtube',
  templateUrl: './template-youtube.component.html'
})
export class TemplateYoutubeComponent {
  @Input() youtubeLink: string;
  safeSrc: SafeResourceUrl = null;
  
  constructor(private sanitizer: DomSanitizer, private platformService: PlatformService) {
  }

  ngOnChanges(changes) {
    if(this.platformService.isPlatformBrowser()){
      this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.youtubeLink);
    }
  }
}