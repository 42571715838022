import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AddAccountDetailsRequest } from 'src/app/models/add-account-details-request.model';
import { UserService } from 'src/app/services/user.service';
import { Store } from "@ngrx/store";
import { User } from 'src/app/models/user.model';
import { selectUser } from "src/app/store/auth/auth.selectors";

enum SubscriptionStep {
  CareerChoice,
  StudyLevelChoice,
  WorkPlaceChoice,
  Recap
}

@Component({
  selector: 'pirog-pro-modal',
  templateUrl: './pirog-pro-modal.component.html',
  styleUrls: ['./pirog-pro-modal.component.scss']
})
export class PirogProModalComponent implements OnInit {

  @ViewChild('pro', { read: TemplateRef }) modalContent:TemplateRef<PirogProModalComponent>;
  @Input() currentStepRecap:any;
  user: User;
  pirogProModal: BsModalRef;
  SubscriptionStep = SubscriptionStep;
  currentStep: SubscriptionStep = SubscriptionStep.CareerChoice;

  pirogProModalSettings = {
    keyboard: true,
    class: "modal-dialog-centered pirog-pro-modal"
  };

  constructor(private store: Store, private modalService: BsModalService, private userService: UserService) { }

  ngOnInit(): void { 
    this._user();
  }
  private _user() {
    const _user = this.store.select(selectUser).subscribe((user) => {
        this.user = user;
        this.currentStep =  this.user?.hasDetails ? SubscriptionStep.Recap : SubscriptionStep.CareerChoice;
    });
  }
  
  public open(): void {
    this.pirogProModal = this.modalService.show(this.modalContent, this.pirogProModalSettings);
  }

  public close(): void {
    this.pirogProModal.hide();
  }

  selectWorkPlace(workplace: string, profile: string) {
    this.submitAccountDetails(profile, workplace);
  }

  changeToStep(step: SubscriptionStep) {
    this.currentStep = step;
  }

  submitAccountDetails(profile: string, workplace = 'OTHER') {
    const request: AddAccountDetailsRequest = {
      profile : profile,
      workPlace : workplace
    }

    this.userService.setUserDetails(request).subscribe(_ => {
      this.currentStep = SubscriptionStep.Recap;
    });
  }
}
