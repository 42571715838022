import { Component, Input, OnInit } from "@angular/core";
import { Router } from '@angular/router';
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { Template } from "src/app/models/template.model";
import { selectTemplates } from 'src/app/store/repository/repository.selector';

@Component({
  selector: "app-products-banner",
  templateUrl: "./products-banner.component.html",
  styleUrls: ["./products-banner.component.scss"]
})
export class ProductsBannerComponent implements OnInit {
  @Input() templateIdToPass : string;
  @Input() details : boolean;
  templates$: Observable<Template[]>;

  constructor(private store: Store, private router: Router) {}

  ngOnInit() {
    this.templates$ = this.store.select(selectTemplates);
  }

  redirectToProductPage(templateName: string) {
    this.router.navigate(['/store', templateName]);
  }
}
