import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlErrorComponent } from './components/control-error.component';
import { ControlErrorDirective } from './directives/control-error.directive';
import { ControlErrorFormSubmitDirective } from './directives/control-error-form-submit.directive';
import { ControlErrorContainerDirective } from './directives/control-error-container.directive';
import { ControlErrorService } from './control-error.service';

@NgModule({
    declarations: [
        ControlErrorComponent,
        ControlErrorDirective,
        ControlErrorContainerDirective,
        ControlErrorFormSubmitDirective,
    ],
    imports: [CommonModule],
    exports: [ControlErrorDirective, ControlErrorContainerDirective, ControlErrorFormSubmitDirective],
    providers: [ControlErrorService],
})
export class ControlErrorModule {}
