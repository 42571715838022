import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';

@Component({
    template: `
        <div class="control-error-message text-warning typo-error" [class.hide]="_hide">
            {{ _text }}
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./control-error.component.scss'],
})
export class ControlErrorComponent {
    _text: any;
    _hide = true;

    @Input() set text(value: string) {
        if (value !== this._text) {
            this._text = value;
            this._hide = !value;
            this.cdr.detectChanges();
        }
    }

    constructor(private cdr: ChangeDetectorRef) {}
}
