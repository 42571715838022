<div class="d-flex flex-column align-items-center text-center m-auto py-4 w-100">
  <div class="my-3">
    <img class="w-100" [src]="stepsImgPath">
  </div>
  <div class="row my-3">
    <div *ngFor="let id of [1,2,3]" class="col-4 px-2">
      <img *ngIf="stepsIconesDir" [src]="stepsIconesDir + 'step_' + id + '.png'" class="w-50 my-3">
      <p [innerHTML]="descriptionKey + '.' + id | translate"></p>
    </div>
  </div>
  <button *ngIf="buttonKey" class="btn btn-dark">
    {{ buttonKey | translate }}
  </button>
</div>

