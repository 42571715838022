import { createReducer, on } from '@ngrx/store';
import {
    loadAuthSuccess,
    loadUserByIdSuccess,
    logout,
    resetPasswordAttemptFailure,
    resetPasswordProcessFailure,
    signInFailure,
    signInSuccess,
    signUpFailure,
    updateUserFailure,
    verifyAccountFailure,
    verifyAccountSuccess,
} from './auth.actions';

import { User } from 'src/app/models/user.model';

export const authFeatureKey = 'auth';

export interface AuthState {
    token: string | null;
    userId: string | null;
    user: User | null;
    error: any | null;
    verifyAccountSuccess: boolean | null;
    hasAcceptedOurConditionsAndPrivacyPolicy: boolean | null;
}

export const initialState: AuthState = {
    token: null,
    userId: null,
    user: null,
    error: null,
    verifyAccountSuccess: null,
    hasAcceptedOurConditionsAndPrivacyPolicy: null
};

function addRoleSuffix(role: string) {
    if (role && !role.startsWith('ROLE_')) {
        return 'ROLE_' + role;
    }
    return role;
}

export const reducer = createReducer(
    initialState,
    on(loadAuthSuccess, (state: AuthState, props: { token: string; role: string; userId: string}) => {
        return {
            ...state,
            token: props.token,
            userId: props.userId,
            user: {
                role: addRoleSuffix(props.role),
                id: props.userId
            },
            error: null,
        };
    }),
    on(signInSuccess, (state: AuthState, props: { bearer: string; userId: string; role: string }) => {
        return {
            ...state,
            token: props.bearer,
            userId: props.userId,
            user: {
                ...state.user,
                role: addRoleSuffix(props.role),
                id: props.userId,
            },
            error: null,
        };
    }),
    on(signInFailure, (state: AuthState, props: { error: any }) => {
        return {
            ...initialState,
            error: props.error,
        };
    }),
    on(
        signUpFailure,
        resetPasswordAttemptFailure,
        resetPasswordProcessFailure,
        (state: AuthState, props: { error: any }) => {
            return {
                ...initialState,
                error: props.error,
            };
        }
    ),
    on(logout, (state: AuthState) => {
        return {
            ...initialState,
        };
    }),
    on(loadUserByIdSuccess, (state: AuthState, props: { user: User }) => {
        return {
            ...state,
            userId: props.user.id,
            user: {
                ...props.user,
                role: addRoleSuffix(props.user.role),
            },
            error: null,
        };
    }),
    on(updateUserFailure, (state: AuthState, props: { error: string }) => {
        return {
            ...state,
            error: props.error,
        };
    }),
    on(verifyAccountSuccess, (state: AuthState) => {
        return {
            ...state,
            verifyAccountSuccess: true,
            error: null,
        };
    }),
    on(verifyAccountFailure, (state: AuthState, props: { error: any }) => {
        return {
            ...initialState,
            verifyAccountSuccess: false,
            error: props.error,
        };
    }),
);
