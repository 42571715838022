import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectUser } from "src/app/store/auth/auth.selectors";
@Component({
  selector: 'app-profile-image',
  templateUrl: './profile-image.component.html',
  styleUrls: ['./profile-image.component.scss']
})
export class ProfileImageComponent implements OnInit {
  user$: Observable<any>;
  @Input() avatarSize: 'sm' | 'md' | 'xl' = 'md';
  @Input() showName: boolean = true;
  
  firstName:string;
  lastName:string;
  picture:string;
  constructor(private store: Store) { }

  ngOnInit(): void {
    this.user$ = this.store.select(selectUser);
    this.user$.subscribe(user => {
      if(user) {
        this.firstName = user.firstname ? user.firstname.charAt(0) : '';
        this.lastName = user.lastname ? user.lastname.charAt(0) : '';
        this.picture= user.picture
      }
    });
  }
}
