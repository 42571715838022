
<button *ngIf="!(isSubscribedToPirogPro$ | async) && !iconMode" [ngClass]="{'bg-gold': gold}" class="btn font-size-large" (click)="openPirogProModal()">
    <i [ngClass]="{'text-white': gold, 'text-gold': !gold}" class="fa fa-crown"></i>
    <span class="text-primary" *ngIf="enableText">{{ buttonText }}</span>
</button>

<a *ngIf="!(isSubscribedToPirogPro$ | async) && iconMode"
 class="text-gold font-size-large border rounded border-gold  pr-2 py-2"
 [class.border]="border"
  (click)="openPirogProModal()">
    <i class="fas fa-crown mx-2"></i>
    <span class="text-white font-size-large" *ngIf="enableText">{{ buttonText }}</span>
</a>

<pirog-pro-modal [currentStepRecap]="currentStepRecap" #pirog_pro_modal>
    <!--  body of the modal  -->
</pirog-pro-modal>
