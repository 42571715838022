import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { Template } from "src/app/models/template.model";
import { Store } from "@ngrx/store";
import { Router } from "@angular/router";
import { selectTemplates } from "src/app/store/repository/repository.selector";

@Component({
  selector: "app-products-list",
  templateUrl: "./products-list.component.html",
  styleUrls: ["./products-list.component.scss"]
})
export class ProductsListComponent implements OnInit {
  @Output() closeMenu = new EventEmitter<void>();

  templates$: Observable<Template[]>;
  constructor(public router: Router, public store: Store) {}  
  ngOnInit(): void {
    this.templates$ = this.store.select(selectTemplates);
  }

  redirectToProductPage(templateName: string) {
    this.router.navigate(['/store', templateName]);
    this.closeMenu.emit();
  }
  
}


