import { createSelector } from '@ngrx/store';
import { AppState } from '..';
import { repositoryFeatureKey, RepositoryState } from './repository.reducer';

export const repositoryFeature = (state: AppState) => state[repositoryFeatureKey];

export const selectCurrentApplication = createSelector(repositoryFeature, (state: RepositoryState) => state.selectedApplication);

export const selectTemplates = createSelector(repositoryFeature, (state: RepositoryState) => state.templates);

export const selectCurrentApplicationIsConfigComplete = createSelector(repositoryFeature, (state: RepositoryState) => state.isSelectedApplicationConfigComplete);

export const selectTemplateByType = (type: string) =>
    createSelector(
        repositoryFeature,
        (state: RepositoryState) =>
            state.templates.filter((template) => {
                return type === template.templateType;
            })[0]
    );

export const selectIsConnectedToWS = createSelector(
    repositoryFeature,
    (state: RepositoryState) => state.connectedToWebSocket
);