<section>
  <div class="container-fluid bg-custom py-5 mb-2">
      <div class="row pb-6">
        <h2 *ngIf="details; else noDetails" class="m-auto text-center text-white display-2 col-12" [innerHTML]="'components.products_banner_home.details.h2' | translate"></h2>
        <p *ngIf="details" class="lead text-center text-white col-12" [innerHTML]="'components.products_banner_home.details.p' | translate"></p>
        <ng-template #noDetails>
          <h2 class="m-auto display-4">
            <span class="text-normal text-white">{{ 'components.products_banner_home.no_details.h2.discover' | translate}}</span>
            <span *ngIf="!templateIdToPass" class="display-4 text-white" [innerHTML]="'components.products_banner_home.no_details.h2.products' | translate"></span>
            <span *ngIf="templateIdToPass" class="display-4 text-white" [innerHTML]="'components.products_banner_home.no_details.h2.other_products' | translate"></span>
          </h2>
        </ng-template>
      </div>

      <div class="row justify-content-center">
        <ng-container *ngFor="let template of templates$ | async">
          <div *ngIf="template.id != templateIdToPass" class="col justify-content-center my-3 my-lg-0" style="flex-grow: 0;">
            <div class="card bg-white" [ngClass]="details ? 'card-custom-details' : 'card-custom'">
              <div class="card-header border-0 d-flex justify-content-center">
                <div class="icone-custom text-center">
                  <img src="assets/templates/{{template.name}}/icone_color-min.webp" class="seo_ratio_1 m-3"
                  alt="{{ 'seo.landing.img_alt.carousel_small_1' | translate }}"/>
                </div>
              </div>

              <div class="card-body text-center d-flex flex-column px-1">
                <div>
                  <h3 class="text-{{template.name}} display-3">{{ template.name }}</h3>
                </div>
                <div>
                  <p>
                    <strong>{{ template.id + '.slogan' | translate }}</strong>
                  </p>
                </div>
                <div class="button-custom">
                  <a class="btn btn-secondary w-auto bg-{{template.name}} text-white border-0 px-0 px-md-auto" type="button"
                    (click)="redirectToProductPage(template.name)">
                    {{'how_it_works'|translate}}
                  </a>
                </div>

                <div *ngIf="details" class="details-custom text-center">
                  <p class="font-weight-bold text-{{template.name}} text-uppercase pb-2">{{ template.id + '.public.plus.title' | translate }}</p>
                  <p><small class="font-weight-light">{{ template.id + '.public.plus.1' | translate }}</small></p>
                  <p><small class="font-weight-light">{{ template.id + '.public.plus.2' | translate }}</small></p>
                  <p><small class="font-weight-light">{{ template.id + '.public.plus.3' | translate }}</small></p>
                </div>
                <p *ngIf="details" class="last-child" [innerHTML]="template.id + '.public.plus.bold' | translate"></p>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
  </div>
</section>

