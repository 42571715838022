<div class="row">
  <div *ngFor="let template of templates$ | async" class="col-6 col-lg-3">
    <app-sub-nav-item 
      [imageUrl]="'assets/templates/' + template.name + '/icone_color-min.webp'"
      [title]="template.name"
      [description]="template.id + '.slogan_2' | translate"
      [path]="'/store/' + template.name"
      (click)="redirectToProductPage(template.name)">

    </app-sub-nav-item>
  </div>
</div>
