
<div class="row pt-2 pb-3 d-flex justify-content-center text-blue">
  <div class="col-1 m-auto text-center d-none d-md-block">
    <i class="display-1 ni ni-bold-left text-{{primaryColorClass}}"></i>
  </div>
  <div *ngFor="let id of [1,2,3]" 
    class="col d-flex flex-column justify-content-center rounded shadow {{ bgCardClass }} p-4 m-2 testimonial-custom">
    <div>
      <img src="assets/img/icons/common/stars.png">
    </div>
    <div class="my-4">
      <p [innerHTML]="descriptionKey + '.' + id + '.testimonial' | translate"></p>
    </div>
    <div>
      <p><strong>{{ 'landing.testimonials.' + id + '.author' | translate }}</strong></p>
    </div>
  </div>
  <div class="col-1 m-auto text-center d-none d-md-block">
    <i class="display-1 ni ni-bold-right text-{{primaryColorClass}}"></i>
  </div>
</div>

<div class="m-auto text-center py-2 d-none d-md-block">
  <span class="bg-{{secondaryColorClass}} dot-custom m-3"></span>
  <span class="bg-{{primaryColorClass}} dot-custom m-3"></span>
  <span class="bg-{{primaryColorClass}} dot-custom m-3"></span>
</div>
