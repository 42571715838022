import { Component, Input } from "@angular/core";

@Component({
  selector: "app-heading-section",
  templateUrl: "./heading-section.component.html",
  styleUrls: ["./heading-section.component.scss"]
})
export class HeadingSectionComponent {
  @Input() titleKey : string;
  @Input() descriptionKey : string;
  @Input() iconPath : string;
  @Input() wheelsArround : boolean = false;
  constructor() {}
}