<div class="card h-90">
    <div class="card-body">
        <img
        class="img-center img-fluid  shadow-lg--hover p-1"
        src="assets/img/pro/{{icon}}.png"
        style="width: 32%;"
      />
      <div class="m-1 text-center">
        <h5 class="h3 title">
          <span class="d-block mb-1">{{'pirog_pro.career_choice.' + key + '_title' | translate}}</span>
          <small class="h4 font-weight-light text-muted">
            {{'pirog_pro.career_choice.' + key + '_description' | translate}}
          </small>
        </h5>
      </div>
    </div>
  </div>
