import { Injectable } from '@angular/core';
import * as Stomp from 'stompjs';
//import SockJS from 'sockjs-client';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root',
})
export class SocketService {
    stompClient: any = null;
    baseUrl = environment.baseUrl;

    constructor(private store: Store, private toastrService: ToastrService) {}

    async _connect(): Promise<any> {
       /* const socket = new SockJS(`${this.baseUrl}/api/ws`);
        this.stompClient = Stomp.over(socket);
        this.stompClient.debug = null;

        return new Promise((resolve, reject) => {
            this.stompClient.connect(
                {},
                function (frame: string) {
                    return resolve(true);
                },
                function () {
                    reject(false);
                }
            );
        });*/
    }

    get stompClientWS() {
        return this.stompClient;
    }

    async _disconnect() {
        /*if (this.stompClient !== null) {
            await this.stompClient.disconnect();
        }*/
    }
}
