/* eslint-disable */
import { InjectionToken } from '@angular/core';

export const defaultErrors = {
    required: () => `This field is required`,
    minlength: ({ requiredLength, actualLength }) => `This field must contain at least ${requiredLength} characters`,
    maxlength: ({ requiredLength, actualLength }) => `This field must not have more than ${requiredLength} characters`,
    min: ({ min, actual }) => `The minimum is ${min}`,
    max: ({ max, actual }) => `The maximum is ${max}`,
    email: () => `This field must be a valid e-mail`,
    pattern: () => `This field must respect the correct format`,
    minAge: () => `The minimal age is 18`,
    maxAge: () => `The maximum age is 100`,
    invalidPromotionalCode: () => `The code is invalid`,
    invalidCSDerogRate: ({ min, max }) => `Please insert a value between ${min} and ${max}`,
};

export const FORM_ERRORS = new InjectionToken('FORM_ERRORS', {
    providedIn: 'root',
    factory: () => defaultErrors,
});
