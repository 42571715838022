<div class="container">
  <div class="row justify-content-center">
    <div class="col-12 col-md-6 d-flex justify-content-center">
      <div class="input-group input-group-alternative input-group-merge search-bar-container w-100">
        <input
          class="form-control"
          [placeholder]="placeholder"
          type="text"
          [(ngModel)]="searchTerm"
          (input)="onSearchTermChange()"
          (keydown)="onKeydown($event)"
        />
        <a class="input-group-append cursor-pointer" (click)="onSearch()" >
          <span class="input-group-text"><i class="fas fa-xl fa-search mr-2"></i></span>
        </a>
      </div>
    </div>
  </div>
</div>
