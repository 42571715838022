import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SubscriptionDetails } from "../models/subscription-details.model";
import { PaymentSessionResponse } from "../models/payment-session-response.model";

@Injectable({
    providedIn: 'root',
})
export class BillingService {
    constructor(private httpClient: HttpClient) {}

    public getSubscriptionDetails(subscriptionId: string) {
        return this.httpClient.get<SubscriptionDetails>(`billing/details/${subscriptionId}`);
    }

    public getPaymentSessionClientSecret() {
        return this.httpClient.get<PaymentSessionResponse>(`billing/session/`);
    }
}
