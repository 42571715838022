<a (click)="redirectToResourcePage()" class="d-flex flex-column flex-sm-row">
  <div>
  <img [src]="imageUrl" [alt]="title" class="img-template-lg m-2" width="50px" />
</div>
    <div class="my-auto text-center text-sm-left " style="white-space: normal;">
      <h4>{{ title }}</h4>
      <h5 class="font-weight-light d-none d-md-block">{{ description }}</h5>
    </div>
  </a>
  
