import {
    ComponentFactoryResolver,
    ComponentRef,
    Directive,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    ViewContainerRef,
} from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FORM_ERRORS } from '../form-errors'; 
import { ControlErrorComponent } from '../components/control-error.component';
import { ControlErrorContainerDirective } from './control-error-container.directive';
import { merge, Subject, Subscription } from 'rxjs';
import { ControlErrorService } from '../control-error.service';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[formControl], [formControlName]',
})
export class ControlErrorDirective implements OnInit, OnDestroy {
    ref: ComponentRef<ControlErrorComponent>;
    container: ViewContainerRef;
    submit$: Subject<any>;
    subscription: Subscription;
    @Input() customErrors = {};
    @Input() ignoreMessage = false;

    constructor(private translateService: TranslateService,
        private vcr: ViewContainerRef,
        private resolver: ComponentFactoryResolver,
        @Optional() controlErrorContainer: ControlErrorContainerDirective,
        //@ts-ignore
        @Inject(FORM_ERRORS) private errors,
        private service: ControlErrorService,
        private controlDir: NgControl
    ) {
        this.container = controlErrorContainer ? controlErrorContainer.vcr : vcr;
        this.submit$ = this.service.submit$;
    }

    ngOnInit(): void {
        this.subscription = merge(this.submit$, this.control.valueChanges, this.control.statusChanges)
            .pipe(untilDestroyed(this))
            .subscribe(() => this.checkControl());
    }

    get control(): AbstractControl {
        // @ts-ignore
        return this.controlDir.control;
    }

    
    checkControl(): void {
        /*const controlErrors = this.control.errors;
        if (controlErrors && !this.ignoreMessage) {
            const firstKey = Object.keys(controlErrors)[0];
            const getError = this.errors[firstKey];
                  
            const defaultErrors = {
                required: "This field is required",
                minlength: "This field must contain at least 8 characters",
                maxlength: "This field must not have more than 25 characters",
                email: "This field must be a valid e-mail",
                pattern: "This field must respect the correct format",
                minAge: "The minimal age is 18",
                maxAge: "The maximum age is 100",
                invalidPromotionalCode: "The code is invalid",
            };
            
            const defaultError = defaultErrors[firstKey];
            let text;
    
            if (defaultError) {
                text = this.translateService.instant("defaultErrors."+defaultError);
            } else {
                text = (this.customErrors[firstKey] && this.customErrors[firstKey](controlErrors[firstKey])) ||
                (getError && getError(controlErrors[firstKey]));
            }
    
            this.setError(text);
        } else {
            this.setError(null);
        }*/
    }
    
    
    setError(text: any): void {
        if (!this.ref) {
            const factory = this.resolver.resolveComponentFactory(ControlErrorComponent);
            this.ref = this.container.createComponent(factory);
        }

        this.ref.instance.text = text;
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
