import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ControlErrorService {
    submit$ = new Subject();
    constructor() {}

    triggerNext(): void {
        // @ts-ignore
        this.submit$.next();
    }
}
