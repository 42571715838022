import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sub-nav-item',
  templateUrl: './sub-nav-item.component.html',
  styleUrls: ['./sub-nav-item.component.scss']
})
export class SubNavItemComponent {
  @Input() imageUrl: string;
  @Input() title: string;
  @Input() description: string;
  @Input() path: string;

  constructor(private router: Router) {}

  redirectToResourcePage() {
    this.router.navigate([this.path]);
  }
}
