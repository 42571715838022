import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-testimonial-message',
  templateUrl: './testimonial-message.component.html',
  styleUrls: ['./testimonial-message.component.scss']
})
export class TestimonialMessageComponent {
  @Input() title: string;
  @Input() subTitle: string;
  @Input() message: string;
  @Input() imagePath: string;
  
  constructor() { }
}