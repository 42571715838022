import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-career-choice-card',
  templateUrl: './career-choice-card.component.html',
  styleUrls: ['./career-choice-card.component.scss']
})
export class CareerChoiceCardComponent implements OnInit {
  @Input() key: string;
  @Input() icon: string;

  constructor() { }

  ngOnInit(): void {
  }

}
