<ng-template #pro>
    <div class="pro-modal-container ">
        <div *ngIf="currentStep === SubscriptionStep.CareerChoice" class="p-2 pb-0">
            <h2 class="h2 text-center mt-2">
                <span class="d-block mb-1">{{'pirog_pro.career_choice.main_question' | translate}} </span>
                <small class="h3 font-weight-light text-muted m-3">
                  {{'pirog_pro.career_choice.description' | translate}}
                </small>
                <span class="position-absolute close-button pointer" (click)="close()">
                  <i class="ni ni-fat-remove"></i>
              </span>
              </h2>
            <div class="row m-2 mt-4">
                <div class="col-md-4">
                  <app-career-choice-card
                    [key]="'association'"
                    [icon]="'non-profit'"
                    (click)="submitAccountDetails('ASSOCIATION')"
                  >
                </app-career-choice-card>
                </div>
                <div class="col-md-4">
                    <app-career-choice-card
                      [key]="'student'"
                      [icon]="'student'"
                      (click)="changeToStep(SubscriptionStep.StudyLevelChoice)"
                    >
                  </app-career-choice-card>
                  </div>
                  <div class="col-md-4">
                    <app-career-choice-card
                      [key]="'personal'"
                      [icon]="'brain'"
                      (click)="submitAccountDetails('PERSONAL')"
                    >
                  </app-career-choice-card>
                  </div>
                  <div class="col-md-4">
                    <app-career-choice-card
                      [key]="'teacher'"
                      [icon]="'professor'"
                      (click)="changeToStep(SubscriptionStep.WorkPlaceChoice)"
                    >
                  </app-career-choice-card>
                  </div>
                  <div class="col-md-4">
                    <app-career-choice-card
                      [key]="'large_business'"
                      [icon]="'entreprise'"
                      (click)="submitAccountDetails('LARGE_BUSINESS')"
                    >
                  </app-career-choice-card>
                  </div>
                  <div class="col-md-4">
                    <app-career-choice-card
                      [key]="'small_business'"
                      [icon]="'entreprise-small'"
                      (click)="submitAccountDetails('SMALL_BUSINESS')"
                    >
                  </app-career-choice-card>
                  </div>
            </div>
        </div>

        <app-survey-form
            *ngIf="currentStep === SubscriptionStep.StudyLevelChoice"

            (submit)="selectWorkPlace($event, 'STUDENT')"
            [question]="'pirog_pro.student_survey.question' | translate"
            [description]="'pirog_pro.student_survey.description' | translate"
            [choices]="['SCHOOL','HIGH_SCHOOL','UNIVERSITY','PRO_SCHOOL']"
            (back)="changeToStep(SubscriptionStep.CareerChoice)"
            [bgUrl]="'student_bg'"
            >
        </app-survey-form>

        <app-survey-form
            *ngIf="currentStep === SubscriptionStep.WorkPlaceChoice"
            [choices]="['PRO_SCHOOL','HIGH_SCHOOL','UNIVERSITY','OTHER']"
            (submit)="selectWorkPlace($event, 'TEACHER')"
            [question]="'pirog_pro.teacher_survey.question' | translate"
            [description]="'pirog_pro.teacher_survey.description' | translate"
            (back)="changeToStep(SubscriptionStep.CareerChoice)"
            [bgUrl]="'professor_bg'"
            >

        </app-survey-form>
        <app-recap-card
        *ngIf="currentStep === SubscriptionStep.Recap"
        (back)="changeToStep(SubscriptionStep.CareerChoice)"
        (close)="close()"
        [hasDetails]="user.hasDetails"
        [currentStepRecap]="currentStepRecap ? currentStepRecap : 'terms'">
        >
        </app-recap-card>
    </div>
</ng-template>
