import { Component } from '@angular/core';

@Component({
    selector: 'app-loader-min',
    templateUrl: './loader-min.component.html',
    styleUrls: ['./loader-min.component.scss'],
})
export class LoaderMinComponent {

}
