import { createReducer, on } from '@ngrx/store';
import { Application } from 'src/app/models/application.model';
import { Template } from 'src/app/models/template.model';
import { loadSelectedApplicationIsConfigCompleteSuccess, loadSelectedApplicationSuccess } from './application/application-repository.actions';
import { connectWebSocketFailure, connectWebSocketSuccess } from './repository.actions';
import { loadTemplatesSuccess } from './template/template.actions';

export const repositoryFeatureKey = 'repository';

export interface RepositoryState {
    selectedApplication: Application;
    isSelectedApplicationConfigComplete: boolean;
    templates: Template[];
    connectedToWebSocket: boolean;
}

export const initialState: RepositoryState = {
    selectedApplication: null,
    isSelectedApplicationConfigComplete: true,
    templates: [],
    connectedToWebSocket: false
};

export const reducer = createReducer(
    initialState,
    on(connectWebSocketSuccess, (state: RepositoryState) => {
        return {
            ...state,
            connectedToWebSocket: true,
        };
    }),
    on(connectWebSocketFailure, (state: RepositoryState) => {
        return {
            ...state,
            connectedToWebSocket: false,
        };
    }),
    on(loadSelectedApplicationSuccess, (state: RepositoryState, props: { application: Application }) => {
        return {
            ...state,
            selectedApplication: props.application,
        };
    }),
    on(loadTemplatesSuccess, (state: RepositoryState, props: { templates: Template[] }) => {
        return {
            ...state,
            templates: props.templates,
        };
    }),
    on(loadSelectedApplicationIsConfigCompleteSuccess, (state: RepositoryState, props: { isConfigComplete: boolean }) => {
        return {
            ...state,
            isSelectedApplicationConfigComplete: props.isConfigComplete,
        };
    }),
);
