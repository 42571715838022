import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { BillingService } from 'src/app/services/billing.service';
import { environment } from 'src/environments/environment';

declare var Stripe;

@Component({
  selector: 'app-recap-card',
  templateUrl: './recap-card.component.html',
  styleUrls: ['./recap-card.component.scss']
})
export class RecapCardComponent implements OnInit, OnDestroy {

  @ViewChild('cardElement') cardElement: ElementRef; 
 
  @Input() currentStepRecap: "description" | "terms" | "stripe_form" = "terms";
  @Input() hasDetails:boolean;
  @Output() back: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();
  
  stripe: any; 
  checkoutForm : any;
  
  constructor(private billingService: BillingService) { }

  ngOnInit(): void {
    this.stripe = Stripe(environment.stripePublicKey);
  }

  setupPaymentMethodForm() {
    this.billingService.getPaymentSessionClientSecret().subscribe(response => {
      this.stripe.initEmbeddedCheckout({
        clientSecret: response.clientSecret
      }).then(uiComponent => {
        this.currentStepRecap = "stripe_form";
        this.checkoutForm = uiComponent;
        this.checkoutForm.mount(this.cardElement.nativeElement);
      });
    })
  }

  changeStep(step) {
    this.currentStepRecap = step;
  }

  goBack() {
    this.back.emit();
  }

  closeModal() {
    this.close.emit();
  }

  cancelOnCardFormStep() {
    this.currentStepRecap = 'terms';
    this.checkoutForm?.destroy();
  }

  ngOnDestroy() {
    this.checkoutForm?.destroy();
  }
}
